import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import ValidationMessage from '@oup/shared-front-end/src/components/ValidationMessage';
import styles from './SystemNotificationBanner.scss';
import withLocalizedContent from '../../../language/withLocalizedContent';
import { getCurrentPlatform } from '../../../utils/platform';
import SVGIcon, { GLYPHS } from '../../../components/SVGIcon/SVGIcon.js';

function SystemNotificationBanner({
  systemNotification = {},
  handleHeaderHeightChange = null,
  localizedContent: { systemNotification: content },
  orbNotifications = []
}) {
  const [showBanner, setShowBanner] = useState(true);
  const platform = getCurrentPlatform();
  const platformNotification = platform && systemNotification[platform];
  const isVisible = platformNotification?.showNotification && !orbNotifications.length;

  if (!isVisible || !showBanner) {
    return null;
  }

  const onBannerClose = () => {
    setShowBanner(!showBanner);
    if (handleHeaderHeightChange) handleHeaderHeightChange();
  };

  return platformNotification.message === content.org_read_only_notification ? (
    <div role="alert" className={styles.platformNotificationTopBanner}>
      <ValidationMessage state="information">{platformNotification.message}</ValidationMessage>
    </div>
  ) : (
    <div className={styles.systemNotificationTopBanner}>
      <div className="grid">
        <div className="row">
          <div className="col">
            <div className={styles.mainStyle}>
              <SVGIcon className={styles.bannerBgIcon} glyph={GLYPHS.ICON_BANNER_BG} />
              <p role="alert" className={styles.textStyle}>
                {platformNotification.message || content.message_for_inner_pages}
              </p>
              <button id="closeBtn" className={styles.close} type="button" onClick={onBannerClose} aria-label="close">
                <SVGIcon glyph={GLYPHS.ICON_CLOSE_BOLD} />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

SystemNotificationBanner.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  systemNotification: PropTypes.object.isRequired,
  orbNotifications: PropTypes.array.isRequired,
  handleHeaderHeightChange: PropTypes.func
};

export default compose(
  connect(state => ({
    systemNotification: state.app.systemNotification || {},
    orbNotifications: state.notifications?.data || []
  })),
  withLocalizedContent('systemNotification')
)(SystemNotificationBanner);
