import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import RadioButtonGroup from '@oup/shared-front-end/src/components/RadioButtonGroup';
import Footer from '@oup/shared-front-end/src/components/Wizard/Footer';
import { ICON_UNKNOWN_USER, ICON_ADD_USER_24, ICON_CLASS } from '@oup/shared-front-end/src/svg/oup/index';
import ValidationMessage from '@oup/shared-front-end/src/components/ValidationMessage';
import TextLink from '@oup/shared-front-end/src/components/TextLink';

import PanelHeading from '../../../PanelHeading/PanelHeading';
import ScrollContainer from '../../../ScrollContainer/ScrollContainer';
import withLocalizedContent from '../../../../language/withLocalizedContent';
import style from './PlacementTestChooseTypeOfTestTakerPanel.scss';

function PlacementTestChooseTypeOfTestTakerPanel({
  isOpen,
  onClose,
  cancelPanel,
  onNext,
  placementTestSessionName,
  localizedContent: { placementTests: placementTestContent, hubGlossary: hubContent },
  isManageSessionEditPanel = false,
  numberOfLicenses = 0
}) {
  const selectionInitialState = '';
  const [selectedOption, setSelectedOption] = useState(selectionInitialState);
  const isDisabledNextBtn = !selectedOption;
  const idDisabledRadioOption = numberOfLicenses === 0;

  const handleOptionSelected = e => {
    setSelectedOption(e.target.value);
  };

  const handleClose = () => {
    setSelectedOption(selectionInitialState);
    onClose();
  };

  useEffect(() => {
    setSelectedOption(selectionInitialState);
  }, [isOpen]);

  return (
    <ScrollContainer
      headerContent={
        <PanelHeading
          title={placementTestContent.choose_type_of_test_taker_panel_title}
          subtitle={(placementTestContent.choose_type_of_test_taker_panel_subtitle || '').replace(
            '[sessionName]',
            placementTestSessionName
          )}
        />
      }
      footerContent={
        <Footer
          primaryButtonLabel={hubContent.next}
          secondaryButtonLabel={hubContent.cancel}
          primaryButtonAction={() => onNext(selectedOption)}
          secondaryButtonAction={isManageSessionEditPanel ? cancelPanel : handleClose}
          primaryButtonAttributes={{
            dataAttributes: {
              testid: 'PLACEMENT_TEST_ONBOARDING_WIZARD_CHOOSE_TYPE_OF_TEST_TAKER_MODAL_NEXT_BUTTON'
            },
            disabled: isDisabledNextBtn
          }}
          secondaryButtonAttributes={{
            dataAttributes: {
              testid: 'PLACEMENT_TEST_ONBOARDING_WIZARD_CHOOSE_TYPE_OF_TEST_TAKER_MODAL_CANCEL_BUTTON'
            }
          }}
        />
      }
    >
      <div className={style.radioList}>
        {idDisabledRadioOption && (
          <div className={style.validationMessageContainer}>
            <ValidationMessage state="warning">
              {placementTestContent.choose_type_of_test_taker_panel_no_licences_warning}
              <TextLink to="#">{placementTestContent.placement_test_add_students_page_buy_more_link_text}</TextLink>
            </ValidationMessage>
          </div>
        )}
        <RadioButtonGroup
          variant="outline"
          name="choose-test-taker-type"
          legend="Choose test taker type"
          checkedValue={[selectedOption]}
          radioGroup={[
            ...[
              {
                id: `add-or-import-new-students`,
                label: placementTestContent.choose_type_of_test_taker_panel_new_student_option_title,
                subLabel: placementTestContent.choose_type_of_test_taker_panel_new_student_option_description,
                value: 'newStudents',
                icon: (
                  <div className={style.icon}>
                    <ICON_ADD_USER_24 />
                  </div>
                ),
                disabled: idDisabledRadioOption
              },
              {
                id: `choose-existing-students-from-your-organization`,
                label: placementTestContent.choose_type_of_test_taker_panel_existing_student_option_title,
                subLabel: placementTestContent.choose_type_of_test_taker_panel_existing_student_option_description,
                value: 'existingStudents',
                icon: (
                  <div className={style.icon}>
                    <ICON_CLASS />
                  </div>
                ),
                disabled: idDisabledRadioOption
              }
            ],
            ...(isManageSessionEditPanel
              ? []
              : [
                  {
                    id: `add-places-to-use-for-unknown-students`,
                    label: placementTestContent.set_number_of_places_for_unknown_students_panel_title,
                    subLabel: placementTestContent.choose_type_of_test_taker_panel_unknown_student_option_description,
                    value: 'unknownStudents',
                    icon: (
                      <div className={style.icon}>
                        <ICON_UNKNOWN_USER />
                      </div>
                    ),
                    disabled: idDisabledRadioOption
                  }
                ])
          ]}
          onChange={handleOptionSelected}
        />
      </div>
    </ScrollContainer>
  );
}

PlacementTestChooseTypeOfTestTakerPanel.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  cancelPanel: PropTypes.func,
  onNext: PropTypes.func.isRequired,
  placementTestSessionName: PropTypes.string.isRequired,
  localizedContent: PropTypes.object.isRequired,
  isManageSessionEditPanel: PropTypes.bool,
  numberOfLicenses: PropTypes.number
};

export default compose(
  withLocalizedContent('hubGlossary', 'placementTests'),
  connect(
    ({ placementTestSessionCreate, identity }) => ({
      currentOrganisationId: identity.currentOrganisationId,
      placementTestSessionId: placementTestSessionCreate.placementTestSessionId,
      placementTestSessionName: placementTestSessionCreate.placementTestSessionNameValue,
      numberOfLicenses: placementTestSessionCreate.numberOfLicenses
    }),
    {}
  )
)(PlacementTestChooseTypeOfTestTakerPanel);
